import * as Sentry from '@sentry/react';

type Env = 'dev' | 'local' | 'stage' | 'staging' | 'production';

export function initSentry(dnsUrl: string, environment: Env) {
  let tracesSampleRate = 0;
  let replaysSessionSampleRate = 0;

  if (
    environment === 'production' ||
    environment === 'stage' ||
    environment === 'staging'
  ) {
    tracesSampleRate = 0.1; // Sample 10% in production and stage
    replaysSessionSampleRate = 0.1; // Sample 10% in production and stage
  }

  Sentry.init({
    dsn: dnsUrl,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate, //  Capture 100% of the transactions
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/safe4entry\.com/,
      /^https:\/\/fromjimmy\.dev/,
    ],
    replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment,
  });
}
